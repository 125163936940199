import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getReceipts(params) {
  return request({
    url: ENDPOINT.RECEIPT,
    method: 'get',
    params
  })
}

export function createReceipt(data) {
  return request({
    url: ENDPOINT.RECEIPT,
    method: 'post',
    data
  })
}

export function deleteReceipt(id) {
  return request({
    url: ENDPOINT.RECEIPT,
    method: 'delete',
    params: {
      id: id
    }
  })
}

export function updateReceipt(data) {
  return request({
    url: ENDPOINT.RECEIPT,
    method: 'patch',
    data
  })
}

export function getReceiptStatistic(params) {
  return request({
    url: ENDPOINT.RECEIPT_STATISTIC,
    method: 'get',
    params
  })
}
