<template>
  <el-row class="manage-filter">
    <el-form :inline="true" label-position="top" :model="form">
      <el-form-item label="Tên nhà hàng">
        <el-select
          v-model="form.placeId"
          filterable
          clearable
          remote
          reserve-keyword
          :remote-method="listRestaurants"
          placeholder="Chọn nhà hàng"
          @change="changeData"
        >
          <el-option
            v-for="place in listPlaces"
            :key="place.id"
            :label="place.name"
            :value="place.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Trạng thái thanh toán">
        <el-select v-model="form.status" @change="changeData">
          <el-option
            v-for="status in paymentStatus"
            :key="status.key"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Ngày tạo">
        <el-date-picker
          v-model="form.createdAt"
          type="datetime"
          format="dd-MM-yyyy HH:mm:ss"
          value-format="yyyy-MM-ddT00:00:00"
          @change="changeData"
        />
      </el-form-item>
      <el-form-item label="Mã đơn hàng">
        <el-input
          v-model="form.searchText"
          placeholder="Nhập mã đơn hàng để tìm kiếm"
          @input="changeData"
        />
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import DISH from '@/data/dish'
import { listPlaces } from '@/services/place'

export default {
  name: 'ManageFilter',
  components: {},
  props: {
    filter: Object
  },
  data() {
    return {
      listPlaces: [],
      form: JSON.parse(JSON.stringify(this.filter))
    }
  },
  computed: {
    ...mapGetters(['language']),
    paymentStatus() {
      return DISH.MANAGE_PAYMENT_STATUS
    }
  },
  beforeMount() {
    this.listRestaurants()
  },
  methods: {
    listRestaurants(key) {
      this.callingAPI = true
      const params = {
        only_parent: true,
        locale: this.language,
        page: 1,
        per_page: 10,
        order: '-created_at',
        search_text: key || '',
        place_types: 'restaurant'
      }
      listPlaces(params).then((response) => {
        this.listPlaces = response.result
        if (this.listPlaces[0] && this.form.placeId !== this.listPlaces[0].id) {
          this.form.placeId = this.listPlaces[0].id
          this.changeData()
        }
      })
    },
    changeData() {
      this.$emit('filter', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-filter {
  width: 100%;
  overflow: auto;
  .el-input {
    width: 300px;
  }
}
</style>
