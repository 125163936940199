<template>
  <div class="list-carts">
    <el-table :data="data" stripe style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="Mã đơn hàng" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="showDetail(scope.row)">
            {{ scope.row.code }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Tổng số món" width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.receipt_lines.length }}
        </template>
      </el-table-column>
      <el-table-column label="Hình thức TT" width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.status === "open" ? "Tiền mặt" : "Online" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        label="Tổng tiền (VNĐ)"
        width="150"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.total | numberWithDelimiter }}
        </template>
      </el-table-column>
      <el-table-column label="Trạng thái ĐH" align="center" min-width="200">
        <template slot-scope="scope">
          <el-tag :type="parseExecutedStatus(scope.row.executed_status).type">
            {{ parseExecutedStatus(scope.row.executed_status).label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Trạng thái TT" align="center" min-width="200">
        <template slot-scope="scope">
          <el-tag :type="parsePaymentStatus(scope.row.status).type">
            {{ parsePaymentStatus(scope.row.status).label }}
          </el-tag>
          <el-button
            v-if="parsePaymentStatus(scope.row.status).action"
            class="ml-2"
            :type="parsePaymentStatus(scope.row.status).type"
            size="small"
            @click="showDetail(scope.row)"
          >
            {{ parsePaymentStatus(scope.row.status).action }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Thao tác" width="100" align="center">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="updateReceipt">
            <span class="el-dropdown-link btn-action">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="status in statuses"
                :key="status.key"
                :command="{ ...scope.row, executed_status: status.value }"
                :disabled="scope.row.executed_status === status.value"
                :divided="!!status.divided"
                :class="`text-${status.type}`"
              >
                {{ status.action }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <detail-receipt
      v-if="visibleDetail"
      :visible="visibleDetail"
      :receipt="selectedReceipt"
      @close="visibleDetail = false"
      @update="handleUpdateReceiptPayment"
    />
  </div>
</template>

<script>
import { parseExecutedStatus, parsePaymentStatus } from '@/utils/filters'
import DISH from '@/data/dish'
import DetailReceipt from '../popup/DetailReceipt'

export default {
  name: 'ListCarts',
  components: {
    DetailReceipt
  },
  props: {
    data: Array
  },
  data() {
    return {
      visibleDetail: false,
      selectedReceipt: null,
      statuses: DISH.MANAGE_CART_STATUS.filter((s) => s.action)
    }
  },
  methods: {
    parseExecutedStatus,
    parsePaymentStatus,
    showDetail(row) {
      this.selectedReceipt = row
      this.visibleDetail = true
    },
    updateReceipt(row) {
      const data = {
        id: row.id,
        customer_name: row.customer_name,
        email: row.email,
        address: row.address,
        phone: row.phone,
        description: row.description,
        is_private: row.is_private,
        total: row.total,
        subtotal: row.subtotal,
        status: row.status,
        executed_status: row.executed_status
      }
      this.visibleDetail = false
      this.$emit('update', data)
    },
    handleUpdateReceiptPayment() {
      this.$confirm('Bạn có chắc chắn không ?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Huỷ',
        type: 'warning'
      }).then(() => {
        this.updateReceipt({
          ...this.selectedReceipt,
          status: parsePaymentStatus(this.selectedReceipt.status).action_value
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-action {
  font-size: 22px;
  cursor: pointer;
}
</style>
