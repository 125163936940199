<template>
  <el-dialog :visible="visible" width="800px" @close="$emit('close')">
    <template slot="title">
      Thông tin đơn hàng
      <b class="text-primary">[{{ receipt ? receipt.code : "" }}]</b>
    </template>
    <h2>
      Nhà hàng :
      <span class="text-primary">
        {{ receipt ? receipt.places[0].translations[0].name : "" }}
      </span>
    </h2>
    <el-table v-if="receipt" :data="receipt.receipt_lines">
      <el-table-column label="Tên món ăn">
        <template slot-scope="scope">
          {{ scope.row.description ? scope.row.description.split("%")[0] : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Loại đồ ăn">
        <template slot-scope="scope">
          {{
            scope.row.description
              ? scope.row.description.split("%")[1]
              : "" | parseDishType
          }}
        </template>
      </el-table-column>
      <el-table-column label="Kích thước" align="center">
        <template slot-scope="scope">
          {{ scope.row.description ? scope.row.description.split("%")[2] : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Ngày đặt">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="Thành tiền (VNĐ)" align="center">
        <template slot-scope="scope">
          {{ scope.row.total | numberWithDelimiter }}
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" @click="$emit('close')">Đóng</el-button>
      <el-button v-if="receipt.status === 'open'" type="primary" @click="$emit('update')">Thanh toán</el-button>
      <el-button v-if="receipt.status === 'paid'" type="primary" @click="$emit('update')">Hoàn tiền</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'DetailReceipt',
  props: {
    visible: Boolean,
    receipt: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
